<template>
  <div class="setBox">
    <div class="boxTop">
      <div class="topLeft" @click="goBack()"></div>
      <div class="topCon">{{ nick }}</div>
      <div class="topRight" @click="showPopup()">
        <img src="../../image/quanz/gd.png" alt="" />
      </div>
    </div>
    <div class="container">
      <div class="ts" v-if="!isFriend">暂非好友关系，您可沟通7句</div>
      <div class="ts" v-if="isFriend"></div>
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          :immediate-check="false"
          offset="800"
        >
          <div v-for="item of messageList" :key="item.ID">
            <div class="opposite" v-if="item.flow === 'in'" >
              <div class="oppositeHear">
                <img :src="item.avatar">
              </div>
              <div class="oppositeContent">
                {{ item.payload.text }}
              </div>
            </div>
            <div class="ownBox" v-if="item.flow === 'out'">
              <div class="ownContent">{{ item.payload.text }}</div>
              <div class="ownHear">
                <img :src="item.avatar">
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
    <div class="inp">
      <input type="text" placeholder="发送消息" v-model="message" v-on:keyup.enter="sendTextMsg"/>
    </div>
    <van-popup v-model="show" round position="bottom">
      <div class="popupDiv" @click="lhEveent()">拉黑</div>
<!--      <div class="popupDiv">举报/投诉</div>-->
      <div class="popupDiv">设置备注和昵称</div>
      <div class="popupDiv" @click="goaddFriends()" v-if="!isFriend">添加好友</div>
      <div class="popupDiv2" @click="deleteFriend" v-if="isFriend">解除好友关系</div>
      <div class="line"></div>
      <div class="popupDiv1" @click="show = false">取消</div>
    </van-popup>
    <van-overlay :show="showOver" @click="showOver = false">
      <div class="wrapper" @click.stop>
        <div class="block">
          <div class="blockTop">将“{{ nick }}”移除黑名单</div>
          <div class="blockContent">
            移除后对方将可以关注你、评论或点赞你的圈子、向你发私信等。
          </div>
          <div class="blockBtn">
            <div @click="showOver = false">取消</div>
            <div @click="removeEvent()">确定</div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import Recorder from "js-audio-recorder";
export default {
  data() {
    return {
      show: false,
      showOver: false,
      nick: '',
      messageList: [],
      nextReqMessageID: '',
      isCompleted: '',
      msgTotal: 0,
      conversationID: '',
      userId: '',   // 当前操作的用户id
      message: '',
      isFriend: false,
      isLoading: false,
      loading: false, //上拉加载
      finished: true, //加载与不加载的状态
    };
  },
  mounted() {
    // this.conversationID = this.$route.query.conversationId;
    this.userId = this.$route.query.userId;
    this.conversationID = "C2C" + this.userId;
    let user = this.$store.state.user;
    let tis = this;
    if (user.circleAuth == 2) {
      // 接收消息监听
      let onMessageReceived = function(event) {
        console.log("onMessageReceived", event)
        // event.data - 存储 Message 对象的数组 - [Message]
        const messages = event.data;
        messages.forEach((message) => {
          console.log(message, "message")
          if (message.type === tis.Tim.TYPES.MSG_TEXT) {
            tis.messageList.push(message)
            // 文本消息 - https://web.sdk.qcloud.com/im/doc/v3/zh-cn/Message.html#.TextPayload
          } else if (message.type === tis.Tim.TYPES.MSG_IMAGE) {
            // 图片消息 - https://web.sdk.qcloud.com/im/doc/v3/zh-cn/Message.html#.ImagePayload
          } else if (message.type === tis.Tim.TYPES.MSG_SOUND) {
            // 音频消息 - https://web.sdk.qcloud.com/im/doc/v3/zh-cn/Message.html#.AudioPayload
          } else if (message.type === tis.Tim.TYPES.MSG_VIDEO) {
            // 视频消息 - https://web.sdk.qcloud.com/im/doc/v3/zh-cn/Message.html#.VideoPayload
          } else if (message.type === tis.Tim.TYPES.MSG_FILE) {
            // 文件消息 - https://web.sdk.qcloud.com/im/doc/v3/zh-cn/Message.html#.FilePayload
          } else {

          }
        });
      };
      this.tim.on(tis.Tim.EVENT.MESSAGE_RECEIVED, onMessageReceived);
      if (!this.tim.isReady()) {
        //SDK 进入 ready 状态时触发，接入侧监听此事件，然后可调用 SDK 发送消息等 API，使用 SDK 的各项功能。
        let onSdkReady = function(event) {
          // console.log("onSdkReady", event)
          tis.getConversationProfile();
          tis.historyMsg();
          tis.setConversationRead();
          tis.checkFriend();
        };
        this.tim.on(this.Tim.EVENT.SDK_READY, onSdkReady);
        //SDK 进入 not ready 状态时触发，此时接入侧将无法使用 SDK 发送消息等功能。如果想恢复使用，接入侧需调用 login 接口，驱动 SDK 进入 ready 状态。
        let onSdkNotReady = function(event) {
          // console.log("onSdkNotReady", event)
          tis.imLogin();
        };
        this.tim.on(this.Tim.EVENT.SDK_NOT_READY, onSdkNotReady);
        tis.imLogin();
      } else {
        tis.getConversationProfile();
        tis.historyMsg();
        tis.setConversationRead();
        tis.checkFriend();
      }
    }
  },
  // destroyed() {
  //   this.tim.logout();
  // },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    showPopup() {
      this.show = true;
    },
    // 拉黑弹框
    lhEveent() {
      this.showOver = true;
      this.show = false;
    },
    // 确定拉黑
    removeEvent() {
      this.show = false;
      this.showOver = false;
      // 请注意：即使只添加一个用户账号到黑名单，也需要用数组类型，例如：userIDList: ['user1']
      let promise = this.tim.addToBlacklist({userIDList: [this.userId + ""]});
      promise.then(function(imResponse) {
        console.log(imResponse.data); // 成功添加到黑名单的账号信息，结构为包含用户 userID 的数组 - [userID]
      }).catch(function(imError) {
        console.warn('addToBlacklist error:', imError); // 添加用户到黑名单列表失败的相关信息
      });
    },
    // 添加好友
    goaddFriends() {
      this.$router.push({ path: "/addFriends" , query: {'userId': this.userId}});
    },
    imLogin() {
      let user = this.$store.state.user;
      if (user.circleAuth == 2 && !this.tim.isReady()) {
        let loginPromise = this.tim.login({userID: user.id + "", userSig: user.imSig});
        loginPromise.then(function (imResponse) {
          if (imResponse.data.repeatLogin === true) {
            // 标识账号已登录，本次登录操作为重复登录。
            console.log(imResponse.data.errorInfo);
          }
        }).catch(function (imError) {
          console.warn('login error:', imError); // 登录失败的相关信息
        });
      }
    },
    // 获取会话信息
    getConversationProfile ()  {
      let tis = this;
      let promise = this.tim.getConversationProfile(this.conversationID);
      promise.then(function(imResponse) {
        // 获取成功
        console.log(imResponse.data.conversation); // 会话资料
        const conversation = imResponse.data.conversation;
        if (conversation.remark === '') {
          tis.nick = conversation.userProfile.nick
        } else {
          tis.nick = conversation.remark
        }
      }).catch(function(imError) {
        console.warn('getConversationProfile error:', imError); // 获取会话资料失败的相关信息
      });
    },
    // 将某会话下所有未读消息已读上报
    setConversationRead() {
      // 将某会话下所有未读消息已读上报
      let promise = this.tim.setMessageRead({conversationID: this.conversationID});
      promise.then(function(imResponse) {
        // 已读上报成功，指定 ID 的会话的 unreadCount 属性值被置为0
        // console.log("setConversationRead", imResponse)
      }).catch(function(imError) {
        // 已读上报失败
        console.warn('setMessageRead error:', imError);
      });
    },
    // 第一次获取历史聊天记录
    historyMsg() {
      let tis = this;
      console.log("conversationID", this.conversationID)
      // 打开某个会话时，第一次拉取消息列表
      let promise = this.tim.getMessageList({conversationID: this.conversationID});
      promise.then(function(imResponse) {
        console.log("imResponse", imResponse)
        tis.messageList = imResponse.data.messageList; // 消息列表。
        tis.nextReqMessageID = imResponse.data.nextReqMessageID; // 用于续拉，分页续拉时需传入该字段。
        tis.isCompleted = imResponse.data.isCompleted; // 表示是否已经拉完所有消息。
      });
      this.msgTotal = tis.messageList.length
    },
    // 拉取更多历史记录
    moreHistoryMsg() {
      let nextReqMessageID = this.nextReqMessageID
      let promise = this.tim.getMessageList({conversationID: this.conversationID, nextReqMessageID});
      let messageList;
      let isCompleted;
      promise.then(function(imResponse) {
        messageList = imResponse.data.messageList; // 消息列表。
        nextReqMessageID = imResponse.data.nextReqMessageID; // 用于续拉，分页续拉时需传入该字段。
        isCompleted = imResponse.data.isCompleted; // 表示是否已经拉完所有消息。
      });
      this.isCompleted = nextReqMessageID;
      this.messageList = messageList;
      this.nextReqMessageID = nextReqMessageID;
    },
    // 发送文本消息
    sendTextMsg() {
      if (!this.isFriend) {
        if (this.messageList.length >= 7) {
          return ;
        }
      }
      let tis = this;
      if (this.message == '') {
        return;
      }
      // 发送文本消息，Web 端与小程序端相同
      // 1. 创建消息实例，接口返回的实例可以上屏
      let message = this.tim.createTextMessage({
        to: this.userId + '',
        conversationType: this.Tim.TYPES.CONV_C2C,
        // 消息优先级，用于群聊。如果某个群的消息超过了频率限制，后台会优先下发高优先级的消息
        // priority: TencentCloudChat.TYPES.MSG_PRIORITY_NORMAL,
        payload: {
          text: this.message
        },
        // 如果您发消息需要已读回执，需购买旗舰版套餐，并且创建消息时将 needReadReceipt 设置为 true
        needReadReceipt: true
        // 消息自定义数据（云端保存，会发送到对端，程序卸载重装后还能拉取到）
        // cloudCustomData: 'your cloud custom data'
      });
      // 2. 发送消息
      let promise = this.tim.sendMessage(message);
      promise.then(function(imResponse) {
        // 发送成功
        // console.log("sendMessage",imResponse);
        // console.log(tis.messageList)
        tis.messageList.push(imResponse.data.message)
        tis.message = '';
      }).catch(function(imError) {
        // 发送失败
        console.warn('sendMessage error:', imError);
      });
      // console.log(tis.messageList)
    },
    checkFriend() {
      let tis = this;
      let promise = this.tim.checkFriend({
        userIDList: [this.userId + ""],
        type: this.Tim.TYPES.SNS_CHECK_TYPE_BOTH,
      });
      promise.then(function(imResponse) {
        const { successUserIDList, failureUserIDList } = imResponse.data;
        // 校验成功的 userIDList
        successUserIDList.forEach((item) => {
          const { userID, code, relation } = item; // 此时 code 始终为0
          // 单向校验好友关系时可能的结果有：
          // - relation: TencentCloudChat.TYPES.SNS_TYPE_NO_RELATION A 的好友表中没有 B，
          // 但无法确定 B 的好友表中是否有 A
          // - relation: TencentCloudChat.TYPES.SNS_TYPE_A_WITH_B A 的好友表中有 B，但无法确定 B 的好友表中是否有 A
          // 双向校验好友关系时可能的结果有：
          // - relation: TencentCloudChat.TYPES.SNS_TYPE_NO_RELATION A 的好友表中没有 B，B 的好友表中也没有 A
          // - relation: TencentCloudChat.TYPES.SNS_TYPE_A_WITH_B A 的好友表中有 B，但 B 的好友表中没有 A
          // - relation: TencentCloudChat.TYPES.SNS_TYPE_B_WITH_A A 的好友表中没有 B，但 B 的好友表中有 A
          // - relation: TencentCloudChat.TYPES.SNS_TYPE_BOTH_WAY A 的好友表中有 B，B 的好友表中也有 A
          // console.log("relation", relation)
          if (relation === tis.Tim.TYPES.SNS_TYPE_BOTH_WAY) {
            tis.isFriend = true;
          }
        });
        // 校验失败的 userIDList
        failureUserIDList.forEach((item) => {
          const { userID, code, message } = item;
        });
      }).catch(function(imError) {
        console.warn('checkFriend error:', imError);
      });
    },
    // 删除好友
    deleteFriend() {
      let tis = this;
      let promise = this.tim.deleteFriend({
        userIDList: [tis.userId + ""],
        type: this.Tim.TYPES.SNS_DELETE_TYPE_SINGLE
      });
      promise.then(function(imResponse) {
        const { successUserIDList, failureUserIDList } = imResponse.data;
        // 删除成功的 userIDList
        successUserIDList.forEach((item) => {
          const { userID } = item;
        });
        // 删除失败的 userIDList
        failureUserIDList.forEach((item) => {
          const { userID, code, message } = item;
        });
        // 如果好友列表有变化，则 SDK 会触发 TencentCloudChat.EVENT.FRIEND_LIST_UPDATED 事件
      }).catch(function(imError) {
        console.warn('removeFromFriendList error:', imError);
      });
    },
    onRefresh() {
      setTimeout(() => {
        this.$toast("刷新成功");
        this.isLoading = false;
        this.moreHistoryMsg();
      }, 1000);
    },
    // // 发送图片消息
    // sendImgMsg() {
    //   // Web 端发送图片消息示例2- 传入 File 对象
    //   // 先在页面上添加一个 id 为 "testPasteInput" 的消息输入框
    //   // 如 <input type="text" id="testPasteInput" placeholder="截图后粘贴到输入框中" size="30" />
    //   document.getElementById('testPasteInput').addEventListener('paste', function(e) {
    //     let clipboardData = e.clipboardData;
    //     let file;
    //     let fileCopy;
    //     if (clipboardData && clipboardData.files && clipboardData.files.length > 0) {
    //       file = clipboardData.files[0];
    //       // 图片消息发送成功后，file 指向的内容可能被浏览器清空，如果接入侧有额外的渲染需求，可以提前复制一份数据
    //       fileCopy = file.slice();
    //     }
    //
    //     if (typeof file === 'undefined') {
    //       console.warn('file 是 undefined，请检查代码或浏览器兼容性！');
    //       return;
    //     }
    //
    //     // 1. 创建消息实例，接口返回的实例可以上屏
    //     let message = this.tim.createImageMessage({
    //       to: 'user1',
    //       conversationType: this.Tim.TYPES.CONV_C2C,
    //       payload: {
    //         file: file
    //       },
    //       onProgress: function(event) { console.log('file uploading:', event) }
    //     });
    //
    //     // 2. 发送消息
    //     let promise = this.tim.sendMessage(message);
    //     promise.then(function(imResponse) {
    //       // 发送成功
    //       console.log(imResponse);
    //     }).catch(function(imError) {
    //       // 发送失败
    //       console.warn('sendMessage error:', imError);
    //     });
    //   });
    // },
    // // 发送音频消息
    // sendAudioMsg() {
    //   // 在 Web 端创建语音消息并发送
    //   // 示例：使用第三方库 js-audio-recorder 录制音频
    //   // 1. 开始录制
    //   let recorder = new Recorder({
    //     // 采样位数，支持 8 或 16，默认是16
    //     sampleBits: 16,
    //     // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值，我的chrome是48000
    //     sampleRate: 16000,
    //     // 声道，支持 1 或 2， 默认是1
    //     numChannels: 1,
    //   });
    //   let startTs;
    //   recorder.start().then(() => {
    //     // 开始录音，记录起始时间戳
    //     startTs = Date.now();
    //   }, (error) => {
    //     // 出错了
    //     console.log(`${error.name} : ${error.message}`);
    //   });
    //
    //   // 2. 结束录制
    //   recorder.stop();
    //
    //   // 3. 计算录音时长，获取 WAV 数据
    //   let duration = Date.now() - startTs; // 单位：ms
    //   let wavBlob = recorder.getWAVBlob();
    //
    //   // 4. blob 数据转成 File 对象
    //   let audioFile = new File([wavBlob], 'hello.wav', { type: 'wav' });
    //   audioFile.duration = duration;
    //
    //   // 5. 创建音频消息
    //   let message = this.tim.createAudioMessage({
    //     to: 'user1',
    //     conversationType: 'C2C',
    //     payload: {
    //       file: audioFile
    //     }
    //   });
    //
    //   // 6. 发送消息
    //   let promise = this.tim.sendMessage(message);
    //   promise.then(function(imResponse) {
    //     // 发送成功
    //     console.log(imResponse);
    //   }).catch(function(imError) {
    //     // 发送失败
    //     console.warn('sendMessage error:', imError);
    //   });
    // },
    // // 发送视频消息
    // sendVideoMsg() {
    //   // web 端发送视频消息示例
    //   // 1. 获取视频：传入 DOM 节点
    //   // 2. 创建消息实例
    //   const message = this.tim.createVideoMessage({
    //     to: 'user1',
    //     conversationType: this.Tim.TYPES.CONV_C2C,
    //     payload: {
    //       file: document.getElementById('videoPicker') // 或者用event.target
    //     },
    //     onProgress: function(event) { console.log('file uploading:', event) }
    //   });
    //   // 3. 发送消息
    //   let promise = this.tim.sendMessage(message);
    //   promise.then(function(imResponse) {
    //     // 发送成功
    //     console.log(imResponse);
    //   }).catch(function(imError) {
    //     // 发送失败
    //     console.warn('sendMessage error:', imError);
    //   });
    // }


  },
};
</script>

<style scoped>
.boxTop {
  width: 750px;
  height: 98px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-top: 18px; */
  position: fixed;
  top: 0px;
}
.topLeft {
  width: 48px;
  height: 48px;
  background-image: url("../../image/back.png");
  background-size: 100% 100%;
  margin-left: 32px;
}
.topCon {
  font-size: 32px;
  font-weight: 600;
  color: #0b1526;
}
.topRight img {
  width: 40px;
  height: 40px;
  margin-right: 32px;
}
.container {
  width: 750px;
  height: 93vh;
  background: #f6f8fa;
  overflow-y: auto;
}
.ts {
  margin-top: 130px;

  font-size: 24px;
  font-weight: 400;
  color: #97a4b4;
  text-align: center;
}
.opposite {
  display: flex;
  margin-left: 32px;
  margin-top: 56px;
}
.oppositeHear {
  width: 80px;
  height: 80px;
  //background: red;
  border-radius: 50%;
}
.oppositeHear img {
  width: 100%;
  border-radius: 50%;
}
.oppositeContent,
.ownContent {
  background-color: #fff;
  max-width: 478px;
  padding: 24px;
  box-sizing: border-box;
  font-size: 28px;
  font-weight: 400;
  color: #0b1526;
  margin-left: 24px;
}
.ownBox {
  display: flex;
  justify-content: flex-end;
  margin-top: 56px;
}
.ownHear {
  width: 80px;
  height: 80px;
  //background: red;
  border-radius: 50%;
  margin-right: 32px;
}
.ownHear img {
  width: 100%;
  border-radius: 50%;
}
.ownContent {
  margin-right: 24px;
}
.popupDiv,
.popupDiv1,
.popupDiv2 {
  width: 750px;
  height: 104px;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  font-size: 32px;
  font-weight: 400;
  color: #0b1526;
  text-align: center;
  line-height: 104px;
  border-bottom: 1px solid #f6f8fa;
}
.popupDiv1 {
  border: none;
}
.popupDiv2 {
  color: #fa2819;
}
.line {
  width: 750px;
  height: 20px;
  background: #f6f8fa;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.block {
  width: 510px;
  height: 346px;
  background: #ffffff;
  border-radius: 24px 24px 24px 24px;
  opacity: 1;
}
.blockTop {
  width: 510px;
  height: 88px;
  border-bottom: 1px solid #f6f8fa;
  font-size: 32px;
  font-weight: 600;
  color: #0b1526;
  text-align: center;
  line-height: 88px;
}
.blockContent {
  font-size: 28px;
  width: 446px;
  font-weight: 400;
  color: #0b1526;
  margin-left: 32px;
  margin-top: 20px;
}
.blockBtn {
  display: flex;
  margin-top: 32px;
}
.blockBtn div:nth-child(1) {
  width: 160px;
  height: 84px;
  background: #f6f8fa;
  border-radius: 12px 12px 12px 12px;
  opacity: 1;
  font-size: 32px;
  font-weight: 400;
  color: #0b1526;
  text-align: center;
  line-height: 84px;
  margin-left: 32px;
}
.blockBtn div:nth-child(2) {
  width: 262px;
  height: 84px;
  background: #0957c3;
  border-radius: 12px 12px 12px 12px;
  opacity: 1;
  font-size: 32px;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 84px;
  margin-left: 24px;
}
.inp {
  width: 750px;
  height: 126px;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  text-align: center;
  position: fixed;
  bottom: 0px;
}
.inp input {
  width: 602px;
  height: 72px;
  background: #f6f8fa;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  border: none;
  font-size: 24px;
  font-weight: 400;
  color: #97a4b4;
}
</style>
